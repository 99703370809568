import { template as template_8c458b56a3b144f09a7914ca093c2286 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8c458b56a3b144f09a7914ca093c2286(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
