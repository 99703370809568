import { template as template_9d4c92d56a6b498eb4c57216b48e1ae0 } from "@ember/template-compiler";
const EmptyState = template_9d4c92d56a6b498eb4c57216b48e1ae0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
