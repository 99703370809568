import { template as template_a3c7a6e3b2244c11a86eb9956827a5dc } from "@ember/template-compiler";
const WelcomeHeader = template_a3c7a6e3b2244c11a86eb9956827a5dc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
